import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import SocialMenu from "../components/socialmenu"
import PostLink from "../components/post-link"
import SchemaBlog from "../components/schema-blog"

const BlogPage = ({
  data: {
    allSitePage: { edges },
  },
  pageContext,
  location,
}) => {
  const Posts = edges.map(edge => {
    return <PostLink key={edge.node.path} post={edge} />
  })

  const metaPosts = edges.map(edge => {
    return {
      id: edge.node.path,
    }
  })

  const { humanPageNumber, numberOfPages, previousPagePath, nextPagePath } = pageContext

  let nextLink = <span />
  let prevLink = <span />
  if (previousPagePath) {
    prevLink = (
      <Link className="pagination-previous" to={previousPagePath} rel="prev">
        prev
      </Link>
    )
  }
  if (nextPagePath) {
    nextLink = (
      <Link className="pagination-next" to={nextPagePath} rel="next">
        next
      </Link>
    )
  }
  return (
    <Layout>
      <SEO title={pageContext.title} pathname="/blog" />
      <SchemaBlog
        pathname={location.pathname}
        title={pageContext.title}
        postList={metaPosts}
        breadcrumbs={pageContext.breadcrumbs}
      />

      <TwoCol
        left={
          <>
            <div className="content">
              <h1>{pageContext.title}</h1>
              {Posts}
            </div>
            <nav
              className="pagination"
              role="navigation"
              aria-label="pagination"
            >
              {prevLink}
              {nextLink}
              <ul className="pagination-list">
                {Array.from({ length: numberOfPages }, (_, i) => (
                  <li key={`pagination-number${i + 1}`}>
                    {i + 1 === humanPageNumber ? (
                      <Link
                        className="pagination-link is-current"
                        key={`pagination-number${i + 1}`}
                        to={`/blog/${i === 0 ? "" : i + 1}`}
                      >
                        {i + 1}
                      </Link>
                    ) : (
                      <Link
                        className="pagination-link"
                        key={`pagination-number${i + 1}`}
                        to={`/blog/${i === 0 ? "" : i + 1}`}
                      >
                        {i + 1}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </>
        }
        right={
          <aside className="menu">
            <SocialMenu />
          </aside>
        }
      />
    </Layout>
  )
}

export default BlogPage
export const pageQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allSitePage(
      sort: { fields: context___date, order: DESC }
      filter: { context: { date: {ne: null}, parent: { eq: "/blog" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          path
          context {
            id
            name
            parent
            title
            depth
            date
            excerpt
          }
        }
      }
    }
  }
`
