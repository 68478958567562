import React from "react"

const SocialMenu = ({ siteTitle }) => (
  <>
    <p className="menu-label">Elsewhere on the web:</p>
    <ul className="menu-list">
      <li>
        <a href="http://soundcloud.com/wirehead">Soundcloud</a>
      </li>
      <li>
        <a href="http://twitter.com/wireheadfm">Twitter</a>
      </li>
    </ul>
  </>
)
export default SocialMenu
